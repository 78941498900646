import styled from 'styled-components';
import colors from '../../theme/colors';

export const StyledContainer = styled.div`
  background-color: ${colors.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 80px 40px;
  position: relative;
  
  @media (min-width: 768px) {
    padding: 40px;
  }
`;
