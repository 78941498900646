import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectChairs, updateChairs } from '../../feature/chairsSlice';
import { AppDispatch } from '../../app/store';
import {
  selectBookedTables, selectTables, updateTables, resetBookedTables,
} from '../../feature/tablesSlice';
import { selectTotalQueue, resetQueue } from '../../feature/queueSlice';
import Button from '../../components/Button';
import Card from '../../components/Card';
import {
  StyledWrapper, StyledError, ButtonsWrapper, StyledCard,
} from './styles';

function EditTable() {
  const dispatch: AppDispatch = useDispatch();
  const tables = useAppSelector(selectTables);
  const bookedTables = useAppSelector(selectBookedTables);
  const chairs = useAppSelector(selectChairs);
  const queue = useAppSelector(selectTotalQueue);
  const [editMode, setEditMode] = React.useState(false);
  const [error, setError] = React.useState('');

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
  }, [editMode, setEditMode]);

  const setChairs = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const newChairsNumber = parseInt(e.target.value, 10);
    dispatch(updateChairs(newChairsNumber));
  }, [useAppDispatch]);

  const setTables = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    const newTablesNumber = parseInt(e.target.value, 10);
    if (bookedTables === 0 || newTablesNumber >= bookedTables) {
      setError('');
      dispatch(updateTables(newTablesNumber));
    } else {
      setError('Number of tables cannot be less than number of tables in use.');
    }
  }, [useAppDispatch, setError]);

  const reset = useCallback(() => {
    dispatch(resetQueue());
    dispatch(resetBookedTables());
  }, [useAppDispatch]);

  const resetAll = useCallback(() => {
    dispatch(updateTables(1));
    dispatch(updateChairs(1));
    dispatch(resetQueue());
    dispatch(resetBookedTables());
  }, [useAppDispatch]);

  return (
    <StyledWrapper>
      <StyledCard className="EditTableCard" label="Tables">
        { editMode
          ? <input type="number" value={tables} onChange={setTables} min={1} />
          : <p>{tables}</p>}
      </StyledCard>
      <StyledCard className="EditTableCard" label="Chairs per table">
        { editMode
          ? <input type="number" value={chairs} onChange={setChairs} min={1} disabled={bookedTables > 0} />
          : <p>{chairs}</p>}
      </StyledCard>
      <Card label="Tables in use">
        <p>{bookedTables}</p>
      </Card>
      <Card label="Groups in queue">
        <p>{queue}</p>
      </Card>
      <ButtonsWrapper>
        <Button onClick={toggleEditMode}>{editMode ? 'Done Editing' : 'Edit tables and chairs'}</Button>
        <Button onClick={reset} background="transparent" border disabled={editMode}>Reset used tables and queue</Button>
        <Button onClick={resetAll} background="transparent" border disabled={editMode}>Reset all</Button>
      </ButtonsWrapper>
      {error.length > 0 && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  );
}

export default EditTable;
