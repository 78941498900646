const colors: { [key:string]: string } = {
  black: '#333333',
  blue: '#EAE8FF',
  green: '#B5DEAD',
  orange: '#D38B5D',
  purple: '#5E4C5A',
  red: '#D38B5D',
  white: '#FFFFFF',
  transparent: 'transparent',
};

export default colors;
