import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// TODO: Create api calls for fetching and updating of chairs

type ChairsState = {
  value: number
};

const initialState: ChairsState = {
  value: 5,
};
export const chairsSlice = createSlice({
  name: 'chairs',
  initialState,
  reducers: {
    updateChairs: (state, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});
export const { updateChairs } = chairsSlice.actions;

export const selectChairs = (state: RootState) => state.chairs.value;

export default chairsSlice.reducer;
