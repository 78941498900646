import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../app/store';
import { updateIsLoggedIn } from '../../feature/authSlice';
import { StyledEmailLoginForm, StyledField, StyledError } from './styles';
import Button from '../../components/Button';

type LoginFormProps = {
  goToAdmin: () => void
};
function LoginForm({ goToAdmin }: LoginFormProps) {
  const dispatch: AppDispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const setLoginCookie = useCallback(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const expires = date.toUTCString();
    document.cookie = `loggedIn=true; expires=${expires}`;
  }, []);

  const login = useCallback(() => {
    if (username.toLowerCase() === 'admin' && password === 'admin123') {
      setError('');
      setLoginCookie();
      dispatch(updateIsLoggedIn(true));
      goToAdmin();
    } else {
      setError('Invalid username or password');
    }
  }, [goToAdmin, setLoginCookie, username, password, dispatch]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    login();
  };

  const onUsernameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUsername(e.target.value);
  }, [setUsername]);

  const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  }, [setPassword]);

  return (
    <StyledEmailLoginForm onSubmit={onSubmit}>
      <StyledField>
        <input
          type="text"
          value={username}
          onChange={onUsernameChange}
          placeholder="Username"
        />
      </StyledField>
      <StyledField>
        <input
          type="password"
          value={password}
          onChange={onPasswordChange}
          placeholder="Password"
        />
      </StyledField>
      <Button type="submit">Login</Button>
      {error.length > 0 && <StyledError>{error}</StyledError>}
    </StyledEmailLoginForm>
  );
}

export default LoginForm;
