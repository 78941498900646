import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StyledContainer } from './styles';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoggedIn } from '../../feature/authSlice';

function MainContainer({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  useEffect(() => {
    if (isLoggedIn && location.pathname !== '/admin') {
      navigate('/admin');
    }
  }, [isLoggedIn, location, navigate]);
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

export default MainContainer;
