import styled from 'styled-components';
import colors from '../../theme/colors';

export const StyledHeader = styled('h1')<{ color?: string; textStyle?: string; }>`
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  font-transform: ${(props) => props.textStyle ?? 'none'};
  margin: 0 0 3rem;
`;
