import React from 'react';
import { StyledCard } from './styles';

type CardProps = {
  label: string;
  children: React.ReactNode;
  className?: string;
  mobileFullWidth?: boolean;
};

function Card({
  label, children, className, mobileFullWidth,
}: CardProps) {
  return (
    <StyledCard className={className} mobileFullWidth={mobileFullWidth}>
      <p className="label">{label}</p>
      {children}
    </StyledCard>
  );
}

export default Card;
