import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { tablesSlice } from '../feature/tablesSlice';
import { chairsSlice } from '../feature/chairsSlice';
import { queueSlice } from '../feature/queueSlice';
import { authSlice } from '../feature/authSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = {
  tables: tablesSlice.reducer,
  chairs: chairsSlice.reducer,
  queue: queueSlice.reducer,
  auth: authSlice.reducer,
};

const createReducer = () => {
  const rootReducer = combineReducers({
    ...reducers,
  });

  return persistReducer(persistConfig, rootReducer);
};

export const store = configureStore({
  reducer: createReducer(),
  middleware: [thunk],
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
