import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../../containers/MainContainer';
import Header from '../../components/Header';
import JoinQueueContainer from '../../containers/JoinQueueContainer';
import BackButton from '../../containers/BackButton';

function JoinQueue() {
  const navigate = useNavigate();

  const goToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <MainContainer>
      <BackButton onClick={goToHome} />
      <Header text="Join Queue" color="purple" textStyle="uppercase" />
      <JoinQueueContainer />
    </MainContainer>
  );
}

export default JoinQueue;
