import React from 'react';
import { StyledButton } from './styles';

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  background?: string;
  color?: string;
  border?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'button';
  disabled?: boolean;
};
function Button({
  children, className, background, color, border, onClick, type, disabled,
}: ButtonProps) {
  return (
    <StyledButton
      type={type}
      className={className}
      onClick={onClick}
      background={background}
      color={color}
      border={border}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
