import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// TODO: Create api calls for fetching and updating of tables

type TablesState = {
  totalValue: number
  bookedTables: number
  currentTables: string[]
};

const initialState: TablesState = {
  totalValue: 5,
  bookedTables: 2,
  currentTables: [],
};
export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    updateTables: (state, action: PayloadAction<number>) => {
      state.totalValue = action.payload;
    },
    increaseBookedTables: (state, action: PayloadAction<number>) => {
      const currentTables = [];
      for (let i = 1; i <= action.payload; i++) {
        currentTables.push(`T${state.bookedTables + i}`);
      }
      state.currentTables = currentTables;
      state.bookedTables += action.payload;
    },
    clearCurrentTables: (state) => {
      state.currentTables = [];
    },
    resetBookedTables: (state) => {
      state.bookedTables = 0;
    },
  },
});
export const {
  updateTables, increaseBookedTables, clearCurrentTables, resetBookedTables,
} = tablesSlice.actions;

export const selectTables = (state: RootState) => state.tables.totalValue;
export const selectBookedTables = (state: RootState) => state.tables.bookedTables;
export const selectAvailableTables = (state: RootState) => state.tables.totalValue - state.tables.bookedTables;
export const selectCurrentTables = (state: RootState) => state.tables.currentTables;

export default tablesSlice.reducer;
