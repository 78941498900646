import React from 'react';
import MainContainer from '../../containers/MainContainer';
import Header from '../../components/Header';
import EditTable from '../../containers/EditTable';
import LogoutButton from '../../containers/LogoutButton';

function Admin() {
  return (
    <MainContainer>
      <Header text="Staff" color="purple" textStyle="uppercase" />
      <EditTable />
      <LogoutButton />
    </MainContainer>
  );
}

export default Admin;
