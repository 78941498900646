import styled from 'styled-components';
import colors from '../../theme/colors';

export const StyledEmailLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  button.login-btn {
    width: 100%;
  }
  .uen-field {
    text-transform: capitalize;
  }
`;

export const StyledField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 20px;
  }
  
  input {
    padding: 8px 10px;
  }
`;

export const StyledError = styled.div`
  color: ${colors.red};
  margin: 10px 0 20px;
`;
