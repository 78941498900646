import styled from 'styled-components';
import colors from '../../theme/colors';
import Card from '../../components/Card';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    max-width: 50%;
  }
`;

export const StyledCard = styled(Card)`
  input{
    display: block;
    width: 40px;
    height: 33px;
    margin: 1rem auto;
    font-family: 'Inter', 'Helvetica Neue', sans-serif;
    font-size: 2rem;
    text-align: center;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  
  button {
    width: 100%;
  }
`;

export const StyledError = styled.div`
  color: ${colors.red};
  margin: 10px 0 20px;
  text-align: center;
  width: 100%;
`;
