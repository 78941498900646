import styled from 'styled-components';
import colors from '../../theme/colors';

export const StyledButton = styled('button')<{ background?: string; color?: string; border?: boolean; }>`
  padding: 8px 20px;
  margin-bottom: 1rem;
  border: ${(props) => (props.border ? `1px solid ${colors.black}` : 'none')};
  border-radius: 20px;
  background: ${(props) => (props.background ? colors[props.background] : colors.orange)};
  color: ${(props) => (props.color ? colors[props.color] : colors.black)};
  cursor: pointer;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  text-align: center;
  
  &:hover {
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }
  
  &:disabled {
    opacity: 0.3;
  }
`;
