import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../app/store';

// TODO: Create api calls for logging in and out

type AuthState = {
  isLoggedIn: boolean
};

const initialState: AuthState = {
  isLoggedIn: false,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
  },
});
export const { updateIsLoggedIn } = authSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;
