import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import { updateIsLoggedIn } from '../../feature/authSlice';
import { AppDispatch } from '../../app/store';

function LogoutButton() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const removeLoginCookie = useCallback(() => {
    document.cookie = 'loggedIn=; expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }, []);

  const logout = useCallback(() => {
    removeLoginCookie();
    dispatch(updateIsLoggedIn(false));
    navigate('/');
  }, [navigate, removeLoginCookie, dispatch]);

  return (
    <Button onClick={logout} background="transparent" border>Log out</Button>
  );
}

export default LogoutButton;
