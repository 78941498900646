import styled from 'styled-components';
import Button from '../../components/Button';

export const StyledButton = styled(Button)`
  position: absolute;
  top: 1rem;
  left: 0;
  display: flex;
  align-items: center;
`;
