import React from 'react';
import { StyledWrapper } from './styles';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentTables } from '../../feature/tablesSlice';

function CustomerTableInfo() {
  const tables = useAppSelector(selectCurrentTables);
  const tableInfo = tables.join(', ');
  const tableWord = tables.length > 1 ? 'Tables' : 'Table';

  return (
    <StyledWrapper>
      <p>
        {tableWord}
        {' '}
        available.
      </p>
      <p>
        {`Please proceed to following ${tableWord.toLowerCase()}`}
        :
      </p>
      <h2>{tableInfo}</h2>
    </StyledWrapper>
  );
}

export default CustomerTableInfo;
