import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../../containers/MainContainer';
import Header from '../../components/Header';
import BackButton from '../../containers/BackButton';
import LoginForm from '../../containers/LoginForm';

function Login() {
  const navigate = useNavigate();

  const goToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const goToAdmin = useCallback(() => {
    navigate('/admin');
  }, [navigate]);

  return (
    <MainContainer>
      <BackButton onClick={goToHome} />
      <Header text="Staff Login" color="purple" textStyle="uppercase" />
      <LoginForm goToAdmin={goToAdmin} />
    </MainContainer>
  );
}

export default Login;
