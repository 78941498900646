import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 40px;
  
  p {
    margin: 0;
  }

  select {
    font-family: 'Inter', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    margin: 20px auto 40px;
    padding: 8px;
  }
`;
