import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoggedIn } from '../../feature/authSlice';

type ProtectedRouteProps = {
  children: React.ReactNode;
};
function ProtectedRoute({ children }: ProtectedRouteProps) {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  return (
    isLoggedIn ? <>{ children }</> : <Navigate to="/login" />
  );
}

export default ProtectedRoute;
