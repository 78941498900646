import React from 'react';
import { StyledWrapper, CardWrapper } from './styles';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentQueue, selectQueue, selectTotalQueue } from '../../feature/queueSlice';
import Card from '../../components/Card';

function CustomerTableInfo() {
  const currentQueue = useAppSelector(selectCurrentQueue);
  const totalQueue = useAppSelector(selectTotalQueue);
  const queue = useAppSelector(selectQueue);
  const queueInfo = queue.find((item) => item.id === currentQueue);
  return (
    <StyledWrapper>
      <p>Queue no.</p>
      <h2>{currentQueue}</h2>
      <CardWrapper>
        <Card label="No. of people" mobileFullWidth>
          <p>{queueInfo?.headcount}</p>
        </Card>
        <Card label="Tables needed" mobileFullWidth>
          <p>{queueInfo?.numberOfTables}</p>
        </Card>
        <Card label="Group(s) ahead:" mobileFullWidth>
          <p>{totalQueue - 1}</p>
        </Card>
      </CardWrapper>
    </StyledWrapper>
  );
}

export default CustomerTableInfo;
