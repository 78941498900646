import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0 0 10px 0;
    &:last-child {
      margin: 0;
    }
  }
  h2 {
    font-size: 2rem;
  }
`;
