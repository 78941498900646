import styled from 'styled-components';
import colors from '../../theme/colors';

export const StyledCard = styled.div<{ mobileFullWidth?: boolean; }>`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: ${(props) => (props.mobileFullWidth ? '100%' : 'calc(50% - 10px)')};
  @media (min-width: 768px) {
    padding: 10px 20px;
    width: calc(50% - 10px);
  }

  .label {
    margin: 0.5rem;
    text-align: center;
  }

  p:not(.label) {
    text-align: center;
    font-size: 2rem;
    margin: 0.5rem auto;
    @media (min-width: 768px) {
      margin: 1rem auto;
    }
  }
`;
