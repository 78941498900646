import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

// TODO: Create api calls for fetching and updating of queues

type QueueState = {
  total: number
  queue: { id: number, headcount: number, numberOfTables: number }[],
  currentQueue: number
};

const initialState: QueueState = {
  total: 1,
  queue: [],
  currentQueue: 100,
};
export const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    increaseQueue: (state, action: PayloadAction<{ headcount: number, numberOfTables: number }>) => {
      state.queue.push({ id: state.currentQueue + 1, headcount: action.payload.headcount, numberOfTables: action.payload.numberOfTables });
      state.currentQueue += 1;
      state.total += 1;
    },
    resetQueue: (state) => {
      state.queue = [];
      state.currentQueue = 100;
      state.total = 0;
    },
  },
});
export const { increaseQueue, resetQueue } = queueSlice.actions;

export const selectTotalQueue = (state: RootState) => state.queue.total;
export const selectQueue = (state: RootState) => state.queue.queue;
export const selectCurrentQueue = (state: RootState) => state.queue.currentQueue;

export default queueSlice.reducer;
