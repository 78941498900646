import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../../containers/MainContainer';
import Header from '../../components/Header';
import BackButton from '../../containers/BackButton';
import { useAppSelector } from '../../app/hooks';
import { selectCurrentQueue } from '../../feature/queueSlice';
import CustomerTableInfo from '../../containers/CustomerTableInfo';
import CustomerQueueInfo from '../../containers/CustomerQueueInfo';

function Queue() {
  const navigate = useNavigate();
  const currentQueue = useAppSelector(selectCurrentQueue);

  const goToJoinQueue = useCallback(() => {
    navigate('/join-queue');
  }, [navigate]);

  return (
    <MainContainer>
      <BackButton onClick={goToJoinQueue} />
      <Header text="Your Queue" color="purple" textStyle="uppercase" />
      {currentQueue > 100 ? <CustomerQueueInfo /> : <CustomerTableInfo />}
    </MainContainer>
  );
}

export default Queue;
