import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyledWrapper } from './styles';
import { useAppSelector } from '../../app/hooks';
import { AppDispatch } from '../../app/store';
import {
  increaseBookedTables, selectAvailableTables, selectTables, clearCurrentTables,
} from '../../feature/tablesSlice';
import { selectChairs } from '../../feature/chairsSlice';
import Button from '../../components/Button';
import { increaseQueue } from '../../feature/queueSlice';

function JoinQueueContainer() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const totalTable = useAppSelector(selectTables);
  const availableTable = useAppSelector(selectAvailableTables);
  const chairsPerTable = useAppSelector(selectChairs);
  const totalCount = useMemo(() => totalTable * chairsPerTable, [availableTable, chairsPerTable]);
  const [headcount, setHeadcount] = useState(1);

  useEffect(() => {
    dispatch(clearCurrentTables);
  }, [dispatch]);

  const renderOptions = useCallback(() => {
    const options = [];
    for (let i = 1; i <= totalCount; i++) {
      options.push(<option key={`option-${i}`} value={i}>{i}</option>);
    }
    return options;
  }, [totalCount]);

  const onHeadcountChange = useCallback((input: number) => {
    setHeadcount(input);
  }, [setHeadcount]);

  const goToQueue = useCallback(() => {
    navigate('/queue');
  }, [navigate]);

  const getQueue = useCallback(() => {
    const numberOfTables = Math.ceil(headcount / chairsPerTable);

    // Get Table
    if (numberOfTables <= availableTable) {
      dispatch(increaseBookedTables(numberOfTables));
    }
    // Get Queue
    else {
      dispatch(increaseQueue({ headcount, numberOfTables }));
    }
    goToQueue();
  }, [headcount]);

  return (
    <StyledWrapper>
      <p>Number of people:</p>
      <select name="headcount" id="headcount" value={headcount} onChange={(e) => onHeadcountChange(parseInt(e.target.value, 10))}>
        {renderOptions()}
      </select>
      <Button onClick={getQueue}>Get Queue</Button>
    </StyledWrapper>
  );
}

export default JoinQueueContainer;
