import React, { useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Home from './pages/Home';
import JoinQueue from './pages/JoinQueue';
import Queue from './pages/Queue';
import Login from './pages/Login';
import Admin from './pages/Admin';
import ProtectedRoute from './containers/ProtectedRoute';
import { AppDispatch } from './app/store';
import { updateIsLoggedIn } from './feature/authSlice';

function App() {
  const dispatch: AppDispatch = useDispatch();
  const checkLogin = useCallback(() => {
    const loggedIn = document.cookie
      .split('; ')
      .find((row) => row.startsWith('loggedIn'))
      ?.split('=')[1];
    if (loggedIn === 'true') {
      dispatch(updateIsLoggedIn(true));
    }
  }, [dispatch]);

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/join-queue" element={<JoinQueue />} />
        <Route path="/queue" element={<Queue />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
