import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../../containers/MainContainer';
import Button from '../../components/Button';
import Header from '../../components/Header';

function Home() {
  const navigate = useNavigate();

  const goToQueue = useCallback(() => {
    navigate('/join-queue');
  }, [navigate]);

  const goToLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <MainContainer>
      <Header text="Welcome" color="purple" textStyle="uppercase" />
      <Button onClick={goToQueue}>Join Queue</Button>
      <Button onClick={goToLogin} background="transparent" border>Staff Login</Button>
    </MainContainer>
  );
}

export default Home;
