import React from 'react';
import { StyledHeader } from './styles';

type HeaderProps = {
  text: string;
  color?: string;
  textStyle?: string;
};

function Header({ text, color, textStyle }: HeaderProps) {
  return (
    <StyledHeader color={color} textStyle={textStyle}>
      {text}
    </StyledHeader>
  );
}

export default Header;
